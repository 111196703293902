import { createReducer } from "@reduxjs/toolkit";
import { initialState, TopBarState } from "./types";
import { getCountries, getProfiles } from "./actions";

export default createReducer(initialState as TopBarState, builder => {
  builder
    .addCase(getProfiles.pending, state => {
      state.profiles.pending = true;
    })
    .addCase(getProfiles.fulfilled, (state, { payload }) => {
      state.profiles.pending = false;
      state.profiles.fullfilled = true;
      state.profiles.data = payload;
    })
    .addCase(getProfiles.rejected, state => {
      state.profiles.pending = false;
      state.profiles.error = true;
    })

    .addCase(getCountries.pending, state => {
      state.countries.pending = true;
    })
    .addCase(getCountries.fulfilled, (state, { payload }) => {
      state.countries.pending = false;
      state.countries.fullfilled = true;
      state.countries.data = payload;
    })
    .addCase(getCountries.rejected, state => {
      state.countries.pending = false;
      state.countries.error = true;
    })
});
