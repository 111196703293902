import axios from '../../api';
import { createAsyncThunk } from "@reduxjs/toolkit";

export const getProfiles = createAsyncThunk('profileList', async () => {
  const response = await axios.get('profile/get-all-profiles');

  return response.data;
});

export const getCountries = createAsyncThunk('countryList', async () => {
  const response = await axios.get('country');

  return response.data;
});

export const updateCountries = createAsyncThunk('updateCountries', async (payload: { countries: number[] }) => {
  await axios.patch('users/update-user-countries', payload);
});
