import React, { useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MenuItem, MenuList, Paper, Popper, Badge } from '@mui/material';
import { ClickAwayListener } from '@mui/base';
import BookmarkBorderOutlined from '@mui/icons-material/BookmarkBorderOutlined';
import Adjust from '@mui/icons-material/Adjust';
import Bookmark from '@mui/icons-material/Bookmark';
import moment from 'moment';
import { ReactComponent as MsgSeen } from './icons/MsgSeenIcon.svg';
import { ReactComponent as NotificationIcon } from './icons/bell_icon.svg';
import styles from './styles.module.scss';
import {
  getUserNotification,
  updateNotificationBookmark,
} from '../../../../../store/user-notification/action';
import { Link } from 'react-router-dom';

const Notification = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const UserNotifications = useSelector(({ UserNotifications }) => UserNotifications);
  const user = useSelector(({ authentication }) => authentication.profile);
  const data = UserNotifications.userNotifications;
  const readNotification = data?.filter(ele => ele.userNotification[0].read === false);
  const anchorRef = useRef(null);

  useEffect(() => {
    dispatch(getUserNotification({ userId: user.id }));
  }, [user, open]);

  const handleClick = event => {
    setOpen(!open);
  };

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  const getDaysInNaturalLanguage = days => {
    if (days === 0) {
      return 'Today';
    } else if (days === 1) {
      return 'Yesterday';
    } else if (days === 7) {
      return '1 week ago';
    } else if (days === 14) {
      return '2 week ago';
    } else if (days === 30) {
      return '1 month ago';
    } else {
      return `${days} days ago`;
    }
  };

  const calculateDateDifference = date => {
    const notificationDate = moment(date).startOf('day');
    const currentDate = moment();
    const diffDays = currentDate.diff(notificationDate, 'days');
    return getDaysInNaturalLanguage(diffDays);
  };

  const MenuItemComponent = ({ item }) => {
    const [bookmarkState, setBookmarkState] = useState(item.userNotification[0]?.bookmark || false);
    const [isRead, setIsRead] = useState(item.userNotification[0]?.read || false);

    const saveBookMarkChanges = bookmarkState => {
      const { id: userNotificationId, read } = item.userNotification[0];
      const userId = item.userNotification[0].user.id;

      if (bookmarkState && !read) {
        const newChangeData = {
          id: item.id,
          userNotification: [
            {
              id: userNotificationId,
              bookmark: true,
              read: true,
              readOn: new Date(),
              user: { id: userId },
            },
          ],
        };
        dispatch(
          updateNotificationBookmark({
            data: newChangeData,
            userId: userId,
            notificationId: item.id,
          }),
        );
      } else {
        const newChangeData = {
          id: item.id,
          userNotification: [
            {
              id: userNotificationId,
              bookmark: bookmarkState,
              user: { id: userId },
            },
          ],
        };
        dispatch(
          updateNotificationBookmark({
            data: newChangeData,
            userId: userId,
            notificationId: item.id,
          }),
        );
      }
    };

    const saveReadChanges = () => {
      const { id: userNotificationId } = item.userNotification[0];
      const userId = item.userNotification[0].user.id;

      const newChangeData = {
        id: item.id,
        userNotification: [
          {
            id: userNotificationId,
            read: true,
            readOn: new Date(),
            user: { id: userId },
          },
        ],
      };
      dispatch(
        updateNotificationBookmark({
          data: newChangeData,
          userId: userId,
          notificationId: item.id,
        }),
      );
    };

    const handleBookmark = () => {
      setBookmarkState(prevState => {
        saveBookMarkChanges(!prevState);
        return !prevState;
      });
    };

    const handleReadMsg = () => {
      if (!isRead) {
        setIsRead(prevState => {
          saveReadChanges();
          return !prevState;
        });
      }
    };

    const SelectBookMarkType = ({ bookmarkState, onClick }) => {
      return bookmarkState ? (
        <Bookmark style={{ fontSize: 16, marginRight: 5, color: 'orange' }} onClick={onClick} />
      ) : (
        <BookmarkBorderOutlined style={{ fontSize: 16, marginRight: 5 }} onClick={onClick} />
      );
    };
    const SelectMsgSeenIcon = ({ isRead }) => {
      return isRead ? (
        <MsgSeen style={{ width: 14, marginRight: 5, marginLeft: 5, color: '#019CDC' }} />
      ) : (
        <Adjust style={{ fontSize: 14, marginRight: 5, marginLeft: 5 }} />
      );
    };

    const GetModifiedNotificationText = () => {
      let notificationText = item.notificationText?.split('$*');
      if (notificationText?.length > 1) {
        const firstPart = notificationText[0];
        notificationText = notificationText[1]?.split('*$');
        const secondPart = notificationText[1];
        return (
          <div className={styles.bottomDiv}>
            {firstPart}
            <Link
              to={`/customer-journey/${item.campaign_id}/${item.node_id}`}
              style={{ color: '#333' }}
            >
              {notificationText[0]}
            </Link>
            {secondPart}
          </div>
        );
      } else {
        return <div className={styles.bottomDiv}>{item.notificationText}</div>;
      }
    };

    return (
      <MenuItem className={styles.menuItem} onClick={handleReadMsg}>
        <div className={styles.menuItemDiv}>
          <div className={styles.topDiv}>
            <div className={styles.campaignName}>{item.campaign}</div>
            <div className={styles.iconDiv}>
              <SelectBookMarkType bookmarkState={bookmarkState} onClick={handleBookmark} />
              <SelectMsgSeenIcon isRead={isRead} />
            </div>
          </div>
          <GetModifiedNotificationText />
          <span style={{ fontSize: '10px', opacity: '0.8' }}>
            {calculateDateDifference(item.createdAt)}
          </span>
        </div>
      </MenuItem>
    );
  };

  const GetMenuItems = () => {
    return (
      <MenuList id="menu-list-grow" onKeyDown={handleListKeyDown} className={styles.menuList}>
        {data?.length > 0 ? (
          data.map(item => <MenuItemComponent item={item} key={item.id} />)
        ) : (
          <MenuItem className={styles.menuItem}>
            <div className={styles.menuItemDiv}>
              <div className={styles.topDiv}>
                <div className={styles.campaignName}></div>
                <div className={styles.iconDiv}></div>
              </div>
              <div className={styles.bottomDiv}>{'no notification'}</div>
            </div>
          </MenuItem>
        )}
      </MenuList>
    );
  };

  return (
    <>
      <Badge
        ref={anchorRef}
        badgeContent={readNotification?.length || 0}
        color="primary"
        style={{ cursor: 'pointer' }}
        onClick={handleClick}
      >
        <NotificationIcon
          aria-controls={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          onClick={handleClick}
          className={styles.notificationIcon}
        />
      </Badge>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom"
        disablePortal
        className={styles.popper}
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [0, 20],
            },
          },
        ]}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <Paper className={styles.paper}>
            <GetMenuItems />
          </Paper>
        </ClickAwayListener>
      </Popper>
    </>
  );
};

export default Notification;
