import { State } from "../default"

const commonState = {
  pending: true,
  error: false,
  fullfilled: false
}

export const initialState = {
  profiles: {
    data: [],
    ...commonState
  },
  countries: {
    data: [],
    ...commonState
  }
}

interface DataArrayState extends State { data: any [] }

export interface TopBarState {
  profiles: DataArrayState,
  countries: DataArrayState
}
